<template>
    <div id="pageDashboard" class="admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <div class="my-3">
                    <h3>Evolution de votre Base personnalisée (90 derniers jours)</h3>
                </div>
            </template>
        </Toolbar>
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loadingchart" style="

          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          z-index: 2;">
            <i class="p-datatable-loading-icon pi-spin pi pi-spinner load_chart"></i>
        </div>

        <div class="grid dashboard">
            <div class="col-12 md:col-6 lg:col-3">
                <div class="widget-overview overview-box-1">
                    <div class="overview-box-title">
                        <span>{{ nameNewText }}</span>
                    </div>
                    <div class="overview-box-count">{{ valueNewText }}</div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <div class="widget-overview overview-box-2">
                    <div class="overview-box-title">
                        <span>{{ nameModifyText }}</span>
                    </div>
                    <div class="overview-box-count">{{ valueModifyText }}</div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <div class="widget-overview overview-box-3">
                    <div class="overview-box-title">
                        <span>{{ nameProjectText }}</span>
                    </div>
                    <div class="overview-box-count">{{ valueProjectText }}</div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
                <div class="widget-overview overview-box-4">
                    <div class="overview-box-title">
                        <span>{{ nameRpealedText }}</span>
                    </div>
                    <div class="overview-box-count">{{ valueRpealedText }}</div>
                </div>
            </div>
            <div class="col-12 lg:col-12">

                <div class="card section_board doughnut">
                    <div class="bg_title" style="display: flex; justify-content: space-between;align-items: center">
                        <h5>Conformité globale</h5>
                        <Button icon="pi pi-download" class="button-table mr-2" mode="basic" accept="image/*"
                                :maxFileSize="1000000"
                                label="Import" chooseLabel="Import" @click="importGlobalConformity()"/>
                    </div>

                    <Chart type="doughnut" ref="chartGlobalConformity" :data="pieDataGlobal" :options="pieOptions"
                           style="width: 50%" :plugins="[chartJsPlugin]"></Chart>
                </div>
            </div>

            <div class="col-12 lg:col-6">
                <div class="card section_board rate">
                    <div class="bg_title">
                        <h5>Taux d'avancement</h5>
                        <Button icon="pi pi-download" class="button-table mr-2" mode="basic" accept="image/*"
                                :maxFileSize="1000000"
                                label="Import" chooseLabel="Import" @click="importProgression()"/>
                    </div>
                    <div class="brogresse_wrapper">
                        <Chart type="bar" ref="chartProgression" :data="barDataSitesProgression" :options="barOptions"
                               :plugins="[chartJsPlugin]" :height="chartProgressionHeight" :key="chartProgressionHeight"
                        ></Chart>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6">
                <div class="card section_board rate">
                    <div class="bg_title">
                        <h5>Taux de conformité</h5>
                        <Button icon="pi pi-download" class="button-table mr-2" mode="basic" accept="image/*"
                                :maxFileSize="1000000"
                                label="Import" chooseLabel="Import" @click="importEvaluation()"/>
                    </div>
                    <div class="brogresse_wrapper">
                        <Chart type="bar" ref="chartEvaluation" :data="barDataSitesEvaluation"
                               :options="barOptionsConformite"
                               :plugins="[chartJsPlugin, dataLabelsPlugin]" :height="chartConformiteHeight"
                               :key="chartConformiteHeight"></Chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</template>
<script>
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {saveAs} from 'file-saver';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "Dashboard",

    data() {
        return {
            chartProgressionHeight: 100,
            chartConformiteHeight: 100,
            chartJsPlugin: {
                beforeDraw: (chartCtx) => {
                    const ctx = chartCtx.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                    ctx.restore();
                },
            },
            dataLabelsPlugin: {
                // anchor: 'center',
                // align: 'end',
                clamp: true,
                rotation: -90,
                font: {
                    size: 12,
                },
                formatter: (value, context) => {
                    const xLabel = context.chart.config.data.labels[context.dataIndex];
                    const datasetLabel = context.dataset.label;
                    return datasetLabel + ' - ' + xLabel + ' (' + Math.round(value) + ')';
                }
            },
            value: 0,
            barDataConformite: {
                labels: ['Paris', 'Bordeaux', 'Le Mans', 'Rennes', 'Rouen'],
                datasets: [
                    {
                        label: "Taux de conformité",
                        backgroundColor: '#08c5d1',
                        borderColor: '#08c5d1',
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: "Taux de conformité réel",
                        backgroundColor: '#3458a7',
                        borderColor: '#3458a7',
                        data: [28, 48, 40, 19, 86, 27, 90, 100]
                    }
                ]
            },
            maxHeight: 500,
            barOptions: {
                height: 500,
                indexAxis: 'y',
                plugins: {
                    legend: {
                        labels: {
                            color: '#A0A7B5'
                        },
                    },
                    datalabels: {
                        color: 'white',
                        font: {
                            weight: 'bold'
                        },
                        align: 'start',
                        anchor: 'end',
                        formatter: (value, context) => {
                            if (context.dataset.data[context.dataIndex] > 0.9) {
                                const datasetLabel = parseFloat(context.dataset.data[context.dataIndex]).toFixed(1) + ' %';
                                return datasetLabel;
                            }
                            else {
                                return '';
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Taux d\'avancement'
                    }
                },
                scales: {
                    x: {
                        max: 100,
                        ticks: {
                            color: '#A0A7B5',
                            autoSkip: false,
                            padding: 10,
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: '#A0A7B5',
                            autoSkip: false,
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    },
                }
            },
            barOptionsConformite: {
                indexAxis: 'y',
                plugins: {
                    legend: {
                        labels: {
                            color: '#A0A7B5'
                        }
                    },
                    datalabels: {
                        color: 'white',
                        // display: function(context) {
                        //   return context.dataset.data[context.dataIndex] > 15;
                        // },
                        font: {
                            weight: 'bold'
                        },
                        align: 'start',
                        anchor: 'end',
                        // formatter: Math.round
                        formatter: (value, context) => {
                            if (context.dataset.data[context.dataIndex] > 0.9) {
                             const datasetLabel = parseFloat(context.dataset.data[context.dataIndex]).toFixed(1)+ ' %';
                            return datasetLabel;
                            }
                            else {
                                return '';
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Taux de conformité'
                    }
                },
                scales: {
                    x: {
                        max: 100,
                        ticks: {
                            color: '#A0A7B5',
                            autoSkip: false,
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#A0A7B5',
                            autoSkip: false,
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    }
                }
            },
            pieData: {
                labels: ['A verifier', 'Conformes', 'Non conformités à venir', 'D'],
                datasets: [{
                    data: [],
                    backgroundColor: [
                        '#f44f01',
                        '#f47202',
                        '#f48901',
                        '#f4b84c',
                        '#a73d13',
                        '#012245',
                        '#003262',
                        '#003d78',
                        '#018d8c',
                        '#8fd7e5',
                        '#d6e7dd',
                    ]
                }]
            },
            pieDataGlobal: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                        '#f44f01',
                        '#f47202',
                        '#f48901',
                        '#f4b84c',
                        '#a73d13',
                        '#012245',
                        '#003262',
                        '#518cc5',
                        '#018d8c',
                        '#8fd7e5',
                        '#d6e7dd',
                    ]
                }]
            },
            pieOptions: {
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: true,
                        labels: {
                            color: '#A0A7B5',
                            padding: 20,
                        },
                        position: 'right',
                    },
                    datalabels: {
                        color: 'white',
                        font: {
                            weight: '300'
                        },
                        formatter: (value, context) => {
                            const datasetLabel = Math.round(context.dataset.data[context.dataIndex]);
                            return datasetLabel;
                        }
                    },
                    title: {
                        display: true,
                        text: 'Conformité globale'
                    }
                },
                responsive: true,
                hoverMode: 'index',
                stacked: false,
            },
            products: null,
            selectedProducts: null,
            valueProgressBar: 38,
            sitesData: null,
            barDataSitesProgression: {
                labels: [],

                datasets: [
                    {
                        label: "Taux d'avancement",
                        backgroundColor: '#08c5d1',
                        borderColor: '#08c5d1',
                        data: []
                    },
                    // {
                    //   label: "Taux d'avancement réel",
                    //   backgroundColor: '#3458a7',
                    //   borderColor: '#3458a7',
                    //   data: []
                    // }

                ],
            },
            barDataSitesEvaluation: {
                labels: [], datasets: [{
                    label: "Taux de conformité",
                    backgroundColor: '#08c5d1',
                    borderColor: '#08c5d1',
                    data: []
                },
                    // {
                    //   label: "Taux de conformité réel",
                    //   backgroundColor: '#3458a7',
                    //   borderColor: '#3458a7',
                    //   data: []
                    // }
                ],
            },
            valueNewText: 0,
            valueModifyText: 0,
            valueProjectText: 0,
            valueRpealedText: 0,
            nameNewText: 'Nouveau texte',
            nameModifyText: 'Texte modifié',
            nameProjectText: 'Projet de texte',
            nameRpealedText: 'Texte abrogé',
            loadingchart: true,
            treeSitesData: [],
            listSiteByEntity: [],
            constTreeSites: [],
            availableDataTreeSites: false,
            fils_sites: [],
            listSites: [],
            nb_loading: 0,
            total_api : 3,
            shouldExecuteMounted: true,
        }
    },
    watch: {
        async id_site() {
            this.listSites = []
            this.nb_loading = 0
            // this.getDocumentWithSiteStat()
            // this.getEvaluationStatus()
            await this.getFilsEntity()
            await this.getStatConformiteSite()
            // this.getSites()


        },
         async list_site(value) {
             if (value) {
                 this.shouldExecuteMounted = false
                 this.loadingchart = true
                 this.listSites = value
                 await this.getDocumentWithSiteStat()
                 await this.getEvaluationStatus()
             }
         }

    },
    interval: null,
    setup() {
        const store = useStore();
        Chart.register(ChartDataLabels);
        const id_site = computed(() => {
            return store.getters.currentSite.id
        });
        const type_site = computed(() => {
            return store.getters.currentSite.type
        });
        const countStatusImport = computed(() => {
            return store.getters.toolsObject.countStatusImport
        })
        const list_site = computed(() => {
            return store.getters.getListSites
        })
        return {store, id_site, type_site, countStatusImport, list_site}
    },
    created() {
    },
    async mounted() {
        if (this.id_site){
            this.listSites = this.list_site
            this.startProgress();
            await this.getFilsEntity()
            await this.getStatConformiteSite()

            setTimeout(async () => {
                if (this.list_site && this.shouldExecuteMounted) {
                    this.shouldExecuteMounted = true
                    await this.getDocumentWithSiteStat();
                    await this.getEvaluationStatus();
                }

            }, "2000");

        }
     else
         this.loadingchart = false

    },


    beforeUnmount() {
        this.endProgress();
    },
    methods: {
        startProgress() {
            this.interval = setInterval(() => {
                let newValue = this.value + Math.floor(Math.random() * 10) + 1;
                if (newValue >= 100) {
                    newValue = 100;
                }
                this.value = newValue;
            }, 2000);
        },
        endProgress() {
            clearInterval(this.interval);
            this.interval = null;
        },
        getSites() {
            //this.loadingchart = true
            this.barDataSitesEvaluation = {
                labels: [],
                datasets: [{
                    label: "Taux de conformité",
                    backgroundColor: '#08c5d1',
                    borderColor: '#08c5d1',
                    data: []
                },

                ],
            }
            this.barDataSitesProgression = {
                labels: [],
                datasets: [
                    {
                        label: "Taux d'avancement",
                        backgroundColor: '#08c5d1',
                        borderColor: '#08c5d1',
                        data: []
                    },
                ],
            }
            if (!this.id_site || this.id_site != 'null') {
                let site = this.listSiteByEntity.length > 0 ? this.listSiteByEntity : [this.id_site]
                this.store.dispatch(Actions.GET_SITES_STAT, {
                    'entity': site,
                    withStat: 1,
                    'customHeader': false
                }).then(data => {
                    //    this.loadingchart = false
                    let progressionLabel = []
                    let progressionSite = []
                    let realProgressionSite = []
                    let evaluationLabel = []
                    let evaluationSite = []
                    let realEvaluationSite = []
                    if (data) {

                        data.forEach(element => {
                            //progression
                            progressionLabel.push(element.name)
                            progressionSite.push(parseFloat(element.progression).toFixed(1))
                            realProgressionSite.push(element.real_progression)
                            //evaluation
                            evaluationLabel.push(element.name)
                            evaluationSite.push(parseFloat(element.conformity).toFixed(1))
                            realEvaluationSite.push(element.real_conformity)
                        })
                    }
                    this.barDataSitesProgression.labels = progressionLabel;
                    this.barDataSitesProgression.datasets[0].data = progressionSite
                    if (progressionLabel.length < 3)
                        this.chartProgressionHeight = 100
                    else if (progressionLabel.length < 5)
                        this.chartProgressionHeight = progressionLabel.length * 50
                    else
                        this.chartProgressionHeight = progressionLabel.length * 30

                    this.barDataSitesEvaluation.labels = evaluationLabel
                    this.barDataSitesEvaluation.datasets[0].data = evaluationSite

                    if (evaluationLabel.length < 3)
                        this.chartConformiteHeight = 100
                    else if (evaluationLabel.length < 5)
                        this.chartConformiteHeight = evaluationLabel.length * 50
                    else
                        this.chartConformiteHeight = evaluationLabel.length * 30

                })
            }
            // else {
            // }
        },
        getEvaluationStatus() {
            this.loadingchart = !!this.id_site
            let dataElem = []
            let dataLabelGlobal = []
            let dataColorLabel = []
            this.pieDataGlobal.datasets[0].data = []
            this.pieDataGlobal.labels = []
            if (!this.id_site || this.id_site != 'null') {
                let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
              return this.store.dispatch(Actions.GET_STATUS_EVALUATION_STAT, {
                    'entity': sites,
                    'withStat': 1,
                    'customHeader': false
                }).then(data => {
                    this.nb_loading ++
                    if ([3, 5].includes(this.nb_loading))
                        this.loadingchart = false
                    if (data) {
                        data.forEach((element) => {
                            if (element.status_count > 0 && element.name != 'Pour information' && element.name != 'Non applicable') {
                                dataLabelGlobal.push(element.name)
                                dataElem.push(element.status_count)
                                dataColorLabel.push(element.color)
                            }
                        })
                        this.pieDataGlobal.labels = dataLabelGlobal
                        this.pieDataGlobal.datasets[0].data = dataElem
                        this.pieDataGlobal.datasets[0].backgroundColor = dataColorLabel
                    }
                })
            } else {
                this.loadingchart = false
            }
        },
        importProgression() {
            saveAs(this.$refs.chartProgression.getChart().toBase64Image('image/jpeg', 1), 'taux-avancement.jpeg');
        },
        importEvaluation() {
            saveAs(this.$refs.chartEvaluation.getChart().toBase64Image('image/jpeg', 1), 'taux-conformité.jpeg');
        },
        importGlobalConformity() {
            saveAs(this.$refs.chartGlobalConformity.getChart().toBase64Image('image/jpeg', 1), 'conformité-globale.jpeg');
        },

        getDocumentWithSiteStat() {
            this.loadingchart = !!this.id_site
            this.valueRpealedText = 0
            this.nameRpealedText = 'Texte abrogé'
            this.valueProjectText = 0
            this.nameProjectText = 'Projet de texte'
            this.valueNewText = 0
            this.nameNewText = 'Nouveau texte'
            this.valueModifyText = 0
            this.nameModifyText = 'Texte modifié'
            if (!this.id_site || this.id_site != 'null') {
                let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
             return  this.store.dispatch(Actions.DOCUMENTS_WITH_SITE_STAT, {
                    'entity': sites,
                    'withStat': 1,
                    'customHeader': false
                }).then(data => {
                    this.nb_loading ++
                    // if ([3, 5].includes(this.nb_loading))
                    //     this.loadingchart = false

                    if (data?.length > 0) {

                        this.valueRpealedText = data[0]['doc_repealed']
                        if (data[0]['doc_repealed'] > 1) {
                            this.nameRpealedText = 'Textes abrogés'
                        } else {
                            this.nameRpealedText = 'Texte abrogé'
                        }

                        this.valueProjectText = data[0]['doc_project']
                        if (data[0]['doc_project'] > 1) {
                            this.nameProjectText = 'Projets de textes'
                        } else {
                            this.nameProjectText = 'Projet de texte'
                        }


                        this.valueNewText = data[0]['doc_new']
                        if (data[0]['doc_new'] > 1) {
                            this.nameNewText = 'Nouveaux textes'
                        } else {
                            this.nameNewText = 'Nouveau texte'
                        }

                        this.valueModifyText = data[0]['doc_updated']
                        if (data[0]['doc_updated'] > 1) {
                            this.nameModifyText = 'Textes modifiés'
                        } else {
                            this.nameModifyText = 'Texte modifié'
                        }
                    }
                })
            }
            else
                this.loadingchart = false
        },

        getFilsEntity() {
            if (this.id_site){
                return  this.store.dispatch(Actions.GET_SITE_BY_PARENT, {entity: this.id_site}).then((data) => {
                    this.fils_sites = data?.map(item => item['id'])
                })
            }
        },

        getStatConformiteSite() {
            this.loadingchart = !!this.id_site
            let sites = []
            if (this.fils_sites?.length>0){
                sites = this.fils_sites
            }
            else
                sites = [this.id_site]

            this.barDataSitesEvaluation = {
                labels: [],
                datasets: [{
                    label: "Taux de conformité",
                    backgroundColor: '#08c5d1',
                    borderColor: '#08c5d1',
                    data: []
                },

                ],
            }
            this.barDataSitesProgression = {
                labels: [],
                datasets: [
                    {
                        label: "Taux d'avancement",
                        backgroundColor: '#08c5d1',
                        borderColor: '#08c5d1',
                        data: []
                    },
                ],
            }

            return this.store.dispatch(Actions.GET_STAT_CONFORMITE_SITE, {sites_fils:sites, month: 'current'}).then((data) => {
                this.nb_loading ++
                if ([3, 5].includes(this.nb_loading))
                    this.loadingchart = false

                let progressionLabel = []
                let progressionSite = []
                let realProgressionSite = []
                let evaluationLabel = []
                let evaluationSite = []
                let realEvaluationSite = []
                if (data) {

                    data.forEach(element => {
                        //progression
                        progressionLabel.push(element.site_name)
                        progressionSite.push(parseFloat(element.progression).toFixed(1))
                        realProgressionSite.push(element.real_progression)
                        //evaluation
                        evaluationLabel.push(element.site_name)
                        evaluationSite.push(parseFloat(element.conformity).toFixed(1))
                        realEvaluationSite.push(element.real_conformity)
                    })
                }
                this.barDataSitesProgression.labels = progressionLabel;
                this.barDataSitesProgression.datasets[0].data = progressionSite
                if (progressionLabel.length < 3)
                    this.chartProgressionHeight = 100
                else if (progressionLabel.length < 5)
                    this.chartProgressionHeight = progressionLabel.length * 50
                else
                    this.chartProgressionHeight = progressionLabel.length * 30

                this.barDataSitesEvaluation.labels = evaluationLabel
                this.barDataSitesEvaluation.datasets[0].data = evaluationSite

                if (evaluationLabel.length < 3)
                    this.chartConformiteHeight = 100
                else if (evaluationLabel.length < 5)
                    this.chartConformiteHeight = evaluationLabel.length * 50
                else
                    this.chartConformiteHeight = evaluationLabel.length * 30
            })
        }
    }
}
</script>
